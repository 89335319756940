import React from "react";

export default function About() {
  return (
    <section className="section about-section">
      <h1 className="section-title">About us</h1>
      <p>
        InstaVege was created to facilitate people in finding proven vegetarian
        and vegan recipes, tasty and easy to cook meals. Many thanks to all
        photographers who made their beautiful pictures of dishes publicly
        available. Bookmark this page because more useful content is coming
        soon.
      </p>
    </section>
  );
}
