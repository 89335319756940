import React from "react";
import Categories from "../components/Categories";
import CocktailList from "../components/CocktailList";
import Header from "../components/Header";
// import SearchForm from "../components/SearchForm";
export default function Home() {
  return (
    <main>
      <Header />
      {/* <SearchForm /> */}
      <Categories />
      <CocktailList />
    </main>
  );
}
