import React from "react";

export default function Tips() {
  return (
    <section className="section about-section tips">
      <h1 className="section-title h1-tips">Tips and useful info</h1>
      <h3>Plan meals for the week</h3>
      <p>
        Since we started planning meals, we reduced significantly time spent in
        the kitchen, food waste, we eat healthier and on the top of that we
        saved some money.😉
      </p>
      <h3>Double or triple the portions</h3>
      <p>
        When the time is scarce we prepare double or triple portions of the
        meals, especially in the case of single pot dishes (stews, soups) and
        refrigerate it for 2-3 days. For those occasions we use sometimes a
        really big pot, or as we call it an “army pot”😊. Doubling the portions
        works also well with not so obvious candidates, like grilled cheese and
        mashed potatoes, but after warming up in the oven for 10 minutes it
        still has a super taste.
      </p>
      <h3>Grilling zucchini and aubergine ahead</h3>
      <p>
        When you make a soup or cook a meal in the oven, consider grilling
        zucchini and aubergine slices on the pan. We use grill pan as it gives
        nice stripes. You can refrigerate the slices in an airtight container
        and use them in the following days as a topping for the sandwiches or
        quesadillas, as a side for a quick lunch (baked or pan grilled camembert
        with grilled zucchini) or dinner (aubergine rolles with mozzarella).
      </p>
      <h3 className>
        Sample weekly+ supermarket buy list with Mediterranean food accent (2
        adults + 2 kids)
      </h3>
      <p className="list-subheading">Bread & Co</p>
      <ul>
        <li>Toast bread 3-4 packs</li>
        <li>Whole grain bread 1 pack</li>
        <li>Sandwich buns 2 packs</li>
        <li>Tortilla 3-4 packs</li>
        <li>Pita bread 2 packs</li>
        <li>Filo pastry (frozen) 2 packs</li>
        <li>Pizza dough 2 packs</li>
        <li>Spaghetti</li>
        <li>Polenta flour</li>
        <li>Arborio rice</li>
      </ul>
      <p className="list-subheading">Vegetables</p>
      <ul>
        <li>Avocado 4</li>
        <li>Olives (Kalamata and green)</li>
        <li>White onion 1 bag</li>
        <li>Red onion 1 bag</li>
        <li>Chives 1 pack</li>
        <li>Veggie mix for soups (frozen)</li>
        <li>Baby spinach (ready to eat) 2 packs</li>
        <li>Salad mix (ready to eat)</li>
        <li>Tomatoes 2 kg</li>
        <li>Cherry tomatoes 0.5 kg</li>
        <li>Cucumber 1 kg</li>
        <li>Potatoes 1 bag</li>
        <li>Aubergine 2 small</li>
        <li>Zucchini 2 small</li>
        <li>Peppers</li>
        <li>Garlic</li>
        <li>white big beans (cooked)</li>
        <li>White cabbage</li>
      </ul>
      <p className="list-subheading">Fruits</p>
      <ul>
        <li>Apples 1 kg</li>
        <li>Bananas 2kg</li>
        <li>Strawberries 0.5 kg (fresh or frozen)</li>
        <li>Blueberries 0.5 kg</li>
        <li>Beetroot (cooked) 0.5 kg</li>
        <li>Lemon 1 kg</li>
      </ul>
      <p className="list-subheading">Dairy</p>
      <ul>
        <li>Butter 2 packs</li>
        <li>Mozzarella 4-5 packs</li>
        <li>Yellow cheese slices 3 packs</li>
        <li>Blue cheese 1 pack</li>
        <li>Feta cheese 3 packs</li>
        <li>Halloumi cheese 2 packs</li>
        <li>Camembert cheese 3 packs</li>
        <li>Parmigiano 1 pack</li>
        <li>Cottage cheese 4 packs</li>
        <li>Greek yogurt 1 pack</li>
        <li>Cream cheese 2 packs</li>
        <li>Kefir 2l</li>
        <li>Milk 1l</li>
      </ul>
      <p className="list-subheading">Spreads and sauces</p>
      <ul>
        <li>Hummus 2 packs</li>
        <li>Vegetable pate</li>
        <li>Ajvar</li>
        <li>Vegan mayo</li>
        <li>Tomato paste</li>
        <li>Tomato puree</li>
        <li>Dried tomato</li>
        <li>Pesto Genovese (green) sauce</li>
      </ul>
      <p className="list-subheading">Nuts</p>
      <ul>
        <li>Salted almonds</li>
        <li>Almond flakes</li>
        <li>Walnuts</li>
        <li>Pine nuts</li>
      </ul>
      <p className="list-subheading">Sweets</p>
      <ul>
        <li>Strawberry jam</li>
        <li>Peanut butter</li>
      </ul>
      <p className="list-subheading">Snacks</p>
      <ul>
        <li>Tortilla chips and salsa</li>
        <li>Coffee nescafe</li>
        <li>Almond milk 2l</li>
      </ul>
      <p className="list-subheading">Oil</p>
      <ul>
        <li>Sunflower oil</li>
        <li>Extra virgin olive oil</li>
        <li>Truffle olive oil</li>
      </ul>
      <p className="list-subheading">Spices</p>
      <ul>
        <li>
          Basil, paprika powder, garlic powder, salt, pepper, oregano, cumin,
          red wine vinegar, white sugar, brown sugar, vanilla and cinnamon
        </li>
      </ul>
    </section>
  );
}
