import React from "react";
import Cocktail from "./Cocktail";
//import Loading from "./Loading";
import { useGlobalContext } from "../context";

const CocktailList = () => {
  const { cocktails } = useGlobalContext();
  // const testArray = ["water", "juice", "vine", "tea"];
  // const testObjectArray = [
  //   { id: 1, name: "water" },
  //   { id: 2, name: "juice" },
  //   { id: 3, name: "vine" },
  //   { id: 4, name: "tea" },
  // ];
  // const findTerm = (term) => {
  //   //let newArray = testArray.filter((drink) => term === drink);
  //   let newArray = testArray.filter((drink) => drink.includes(term));
  //   let newObjectArray = testObjectArray.filter((drink) =>
  //     drink.name.includes(term)
  //   );
  //   //console.log(newArray);
  //   //console.log(newObjectArray);
  // };
  // findTerm("i");
  if (cocktails.length < 1) {
    return (
      <>
        <h2 className="section-title no-recipes">
          There are no recipes that matched your search criteria.
        </h2>
        <h2 className="section-title no-recipes no-recipes-cont">
          Please try again or choose from a category above.
        </h2>
      </>
    );
  }
  return (
    // <div>
    //   <h2>cocktail list component</h2>
    // </div>
    <section className="section">
      {/* <h2 className="section-title">cocktails</h2> */}
      <div className="cocktails-center">
        {cocktails.map((item) => {
          return <Cocktail key={item.id} {...item} />;
        })}
      </div>
    </section>
  );
};

export default CocktailList;
