import React from "react";
import { Link } from "react-router-dom";
// import logo from "../logo.svg";
export default function Navbar() {
  return (
    <nav className="navbar">
      <div className="nav-center">
        <Link to="/">
          {/* <img src={logo} alt="cocktail db logo" className="logo" /> */}
          <h3 className="logo">
            <span className="insta">Insta</span>Vege
          </h3>
        </Link>
        <ul className="nav-links">
          <li>
            <Link to="/tips">tips</Link>
          </li>
          <li>
            <Link to="/about">about</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
