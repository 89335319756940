import React, { useState, useContext, useEffect } from "react";
//import { useCallback } from "react";
import drinks from "./dataDrinks";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState("a");
  const [cocktails, setCocktails] = useState(drinks);
  const allCategories = [
    "all",
    ...new Set(drinks.map((drink) => drink.category)),
  ];
  // eslint-disable-next-line
  const [categories, setCategories] = useState(allCategories);
  //console.log(allCategories);
  //const testArray = ["water", "juice", "vine", "tea"];
  //let newArray = testArray.filter((drink) => term === drink);
  const findTerm = (searchTerm) => {
    setCocktails(drinks);
    let newArray = drinks.filter((drink) =>
      drink.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    //console.log(newArray);
    setCocktails(newArray);
  };
  //findTerm("b");
  //findTerm(searchTerm);
  useEffect(() => {
    findTerm(searchTerm);
  }, [searchTerm]);
  console.log(searchTerm);

  const filterItems = (category) => {
    if (category === "all") {
      setCocktails(drinks);
      return;
    }
    const newItems = drinks.filter((item) => item.category === category);
    console.log(newItems);
    setCocktails(newItems);
  };
  return (
    <AppContext.Provider
      value={{ cocktails, searchTerm, setSearchTerm, categories, filterItems }}
    >
      {children}
    </AppContext.Provider>
  );
};
// make sure use
export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
