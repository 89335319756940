import React from "react";
import { Link } from "react-router-dom";
import { FaRegClock } from "react-icons/fa";
export default function Cocktail({ image, time, type, name, id }) {
  return (
    <article className="cocktail">
      <div className="img-container">
        <img src={image} alt={name} />
      </div>
      <div className="cocktail-footer">
        <div className="recipe-meta">
          <span className="recipe-time">
            <FaRegClock className="clock-icon" />
            <span className="time">{time}</span>
          </span>
          <span className="vege-type">{type}</span>
        </div>
        <h3 className="recipe-title">{name}</h3>
        {/* <h4>{glass}</h4>
        <p>{info}</p> */}
        <Link to={`/meal/${id}`} className="btn btn-primary btn-details">
          view recipe
        </Link>
      </div>
    </article>
  );
}
