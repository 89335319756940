import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import data from "../dataSingleCocktails";

// const scrollToTop = () => {
//   window.scrollTo(0, 0);
// };

const SingleCocktail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();

  const singleCocktail = data.find((cocktail) => cocktail.id === id);
  //console.log(newCocktail);

  const { name, image, instructions, ingredients } = singleCocktail;
  return (
    <section className="cocktail-section">
      {/* <Link to="/" className="btn btn-primary">
        back home
      </Link> */}
      <h2 className="single-dish-title">{name}</h2>
      <div className="drink">
        <figure>
          <img src={image} alt={name}></img>
        </figure>
        <div className="ingredients-box">
          <h2 className="single-dish-h2">Ingredients</h2>
          <ul className="ingredients-list">
            {ingredients.map((item, index) => {
              return (
                <li className="ingredients-li" key={index}>
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
        <br className="br" />
        <div className="instructions-box">
          <h2 className="single-dish-h2">Cooking Instructions</h2>
          <ul>
            {instructions.map((item, index) => {
              return (
                <>
                  <li className="instructions-li" key={index}>
                    <span>{index + 1}.</span>
                    {item}
                  </li>
                  <hr />
                </>
              );
            })}
          </ul>
        </div>
      </div>
      <Link to="/" className="btn btn-primary btn-bck">
        back to recipes
      </Link>
    </section>
  );
};

export default SingleCocktail;
