const cocktails = [
  {
    id: "4323142600",
    time: "10 min",
    type: "vegan",
    category: "Breakfast",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678269290/food/avocado_toast2_wezbuo.jpg",
    name: "Avocado toast",
  },
  {
    id: "3323104030",
    time: "5+15 min",
    type: "vegan",
    category: "Lunch",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1677839876/food/chickpea_curry_gxosij.jpg",
    name: "Chickpea curry",
  },
  {
    id: "3323104000",
    time: "10+15 min",
    type: "vegetarian",
    category: "Side",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1677835946/food/grilled_asparagus_kqnekc.jpg",
    name: "Grilled asparagus",
  },
  {
    id: "3323104010",
    time: "10+20 min",
    type: "vegan",
    category: "Soup",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1677835946/food/cream_of_broccoli_jc8jam.jpg",
    name: "Broccoli soup",
  },
  {
    id: "6323102500",
    time: "10 min",
    type: "vegetarian",
    category: "Salad",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678094610/food/greek_salad_wxuvfx.jpg",
    name: "Greek salad",
  },
  {
    id: "6323122200",
    time: "5+30 min",
    type: "vegetarian",
    category: "Desert",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678101841/food/rice_pudding_gbnus0.jpg",
    name: "Rice pudding",
  },
  {
    id: "6323132010",
    time: "10 min",
    type: "vegan",
    category: "Smoothie",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678105279/food/blueberry_beetroot_smoothie_k4aj83.jpg",
    name: "Blueberry beetroot",
  },
  {
    id: "6323141400",
    time: "5+20 min",
    type: "vegan",
    category: "Other",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678108449/food/strawberry_sauce_eekk89.jpg",
    name: "Strawberry sauce",
  },
  {
    id: "3323104055",
    time: "5+10 min",
    type: "vegetarian",
    category: "Lunch",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1677839876/food/grilled_halloumi_cheese_lmmksw.jpg",
    name: "Grilled halloumi",
  },
  {
    id: "832392310",
    time: "5+20 min",
    type: "vegetarian",
    category: "Side",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678264779/food/potatoes_gmve68.jpg",
    name: "Mashed potatoes",
  },
  {
    id: "080323095510",
    time: "1+15 min",
    type: "vegetarian",
    category: "Lunch",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678265642/food/pierogi_ldopd1.jpg",
    name: "Pierogi",
  },
  {
    id: "080323122900",
    time: "10+15 min",
    type: "vegetarian",
    category: "Lunch",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678269289/food/tomato_pasta_tsus6d.jpg",
    name: "Cherry tomato pasta",
  },
  {
    id: "080323131000",
    time: "10 min",
    type: "vegetarian",
    category: "Salad",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678277551/food/tzatziki_iehhur.jpg",
    name: "Tzatziki",
  },
  {
    id: "0903230954",
    time: "15+40 min",
    type: "vegetarian",
    category: "Breakfast",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678269288/food/cheese_pie_u0olyl.jpg",
    name: "Cheese pie",
  },
  {
    id: "090323110800",
    time: "10+50 min",
    type: "vegetarian",
    category: "Lunch",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678351711/food/mac_and_cheese_jocux1.jpg",
    name: "Mac and Cheese",
  },
  {
    id: "090323115200",
    time: "15+ min",
    type: "vegetarian",
    category: "Lunch",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678269290/food/quesadilla_jd3eqs.jpg",
    name: "Quesadilla",
  },
  {
    id: "090323124800",
    time: "20 min",
    type: "vegan",
    category: "Side",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678269289/food/grilled_zucchini_lsru9s.jpg",
    name: "Grilled zucchini",
  },
  {
    id: "090323133500",
    time: "20 min",
    type: "vegetarian",
    category: "Side",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678278107/food/aubergine_rolls_yjm9uu.jpg",
    name: "Aubergine rolls",
  },
  {
    id: "090323141400",
    time: "10 min",
    type: "vegetarian",
    category: "Breakfast",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678367494/food/caprese_buns_kcthoy.jpg",
    name: "Caprese buns",
  },
  {
    id: "090323145300",
    time: "10 min",
    type: "vegan",
    category: "Breakfast",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678368955/food/porridge_af9epe.jpg",
    name: "Porridge",
  },
  {
    id: "100323100100",
    time: "15+40 min",
    type: "vegan",
    category: "Soup",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678439059/food/carrots_sweet_potatoes_rdb3hv.jpg",
    name: "Carrots soup",
  },
  {
    id: "100323102300",
    time: "5 min",
    type: "vegan",
    category: "Breakfast",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678439313/food/peanut_butter_and_jam_trjiqe.jpg",
    name: "Peanut butter and jam",
  },
  {
    id: "100323104900",
    time: "5 min",
    type: "vegan",
    category: "Smoothie",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1678439512/food/spinach_smoothie_jgaaqp.jpg",
    name: "Spinach smoothie",
  },
  {
    id: "210323103800",
    time: "25 min",
    type: "vegan",
    category: "Lunch",
    image:
      "https://res.cloudinary.com/dfzvhcazw/image/upload/v1679391447/food/udon_veggies_oxv7bn.jpg",
    name: "Udon veggies",
  },
];

export default cocktails;
