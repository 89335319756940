import React from "react";
import SearchForm from "./SearchForm";

function Header() {
  return (
    <div className="header">
      <SearchForm />
    </div>
  );
}

export default Header;
