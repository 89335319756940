import React from "react";
import { useGlobalContext } from "../context";

function Categories() {
  const { categories, filterItems } = useGlobalContext();
  return (
    <div className="category-container">
      <p className="choose-a-category">Choose a category</p>
      <div className="btn-container">
        {categories.map((category, index) => {
          return (
            <button
              type="button"
              className="filter-btn"
              key={index}
              onClick={() => filterItems(category)}
            >
              {category}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default Categories;
